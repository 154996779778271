*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:root {
  --primary-color: #fff;
  --secondary-color: rgba(255, 255, 255, 0.8);
  --background-color: #000;
  --accent-color: rgba(255, 255, 255, 0.2);
  --transition-speed: 0.2s;
}

html, body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'SF Pro Display', 'Segoe UI', Roboto, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: var(--background-color);
  color: var(--primary-color);
}

#root {
  min-height: 100vh;
}

/* Smooth scrolling for the whole page */
html {
  scroll-behavior: smooth;
}

/* Custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}

/* Selection color */
::selection {
  background: rgba(255, 255, 255, 0.2);
  color: var(--primary-color);
}

/* Button and interactive element focus states */
button:focus, a:focus {
  outline: none;
}

/* Disable tap highlight on mobile */
* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}

/* Smooth transitions for color changes */
a, button {
  transition: all var(--transition-speed) ease;
  -webkit-tap-highlight-color: transparent;
  user-select: none;
}

/* Keep focus outline for keyboard navigation only */
*:focus:not(:focus-visible) {
  outline: none;
}

*:focus-visible {
  outline: 2px solid rgba(255, 255, 255, 0.3);
  outline-offset: 2px;
}

/* Improved font rendering */
@media screen and (-webkit-min-device-pixel-ratio: 2),
       screen and (min-resolution: 2dppx) {
  body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

/* Responsive text sizing */
@media (max-width: 768px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 1920px) {
  html {
    font-size: 18px;
  }
}
